///var/www/website2024/admin.siamweeds.com/myapp/src/components/PriceOptions.js
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'primereact/dropdown'; // Import the Dropdown component from PrimeReact
import { InputNumber } from 'primereact/inputnumber';  // Import InputNumber component from PrimeReact
import '../css/price-options.scss';

const PriceOptions = ({
  priceOptions,
  handlePriceOptionChange,
  addPriceOption,
  setPriceOptions,
  removePriceOption,
  statusOptions,
}) => {
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [isExpanded, setIsExpanded] = useState({}); // Track expand/collapse per option

  // Fetch currency options
  useEffect(() => {
    fetch('/json-data/currencies.json')
      .then(response => response.json())
      .then(data => setCurrencyOptions(data.currencies))
      .catch(error => console.error('Error loading currencies:', error));
  }, []);



const toggleExpand = (index) => {
  setIsExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
};




  const renderQuantityUnitInput = (option, index, quantityName, unitType) => {
    const unitOptions = [
      { label: option.unit_large_name || 'Large Unit', value: 1 },  // Handle undefined names
      { label: option.unit_small_name || 'Small Unit', value: 2 },  // Handle undefined names
    ];

    return (
      <div className="p-inputgroup">
        <InputNumber
          value={option[quantityName] || 0}  // Ensure a default value for quantity
          onValueChange={(e) => handlePriceOptionChange(index, { target: { name: quantityName, value: e.value } })}
          showButtons
          min={0}
          max={10000}
          mode="decimal"
          step={1}
          className="p-mr-2"
        />
        <Dropdown
          value={option[unitType] || 1}  // Ensure a default value for unit type
          options={unitOptions}
          onChange={(e) => handlePriceOptionChange(index, { target: { name: unitType, value: e.value } })}
          placeholder="Select Unit"
        />
      </div>
    );
  };



  return (
    <div className="mb-3">
      <label htmlFor="ServiceName" className="form-label">Price options:</label>
      <table className="table table-responsive">
        <thead>
          <tr>
            <th>Actions</th>
            <th>Type</th>
            <th>Price Value</th>
            <th>Currency</th>
            <th>Status</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {priceOptions.map((option, index) => (
            <React.Fragment key={index}>
              <tr>
                <td>
                  <button
                    type="button"
                    className={`btn ${index === 0 ? 'btn-dark' : 'btn-danger'} btn-sm`}
                    onClick={() => index === 0 ? addPriceOption() : removePriceOption(index)}
                  >
                    <FontAwesomeIcon icon={index === 0 ? faPlus : faTrash} />
                  </button>
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    name="PriceType"
                    value={option.PriceType}
                    onChange={e => handlePriceOptionChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    name="PriceValue"
                    value={option.PriceValue}
                    onChange={e => handlePriceOptionChange(index, e)}
                  />
                </td>
                <td>
                  <select
                    className="form-select"
                    name="currency"
                    value={option.currency}
                    onChange={e => handlePriceOptionChange(index, e)}
                  >
                    {currencyOptions.map(currency => (
                      <option key={currency.id} value={currency.name}>
                        {currency.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    className="form-select"
                    name="status"
                    value={option.status}
                    onChange={e => handlePriceOptionChange(index, e)}
                  >
                    <option value="">Select Status</option>
                    {statusOptions.map(statusOption => (
                      <option key={statusOption.id} value={statusOption.id}>
                        {statusOption.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <button
                    className="btn btn-secondary btn-sm"
                    type="button"
                    onClick={() => toggleExpand(index)}
                  >
                    {isExpanded[index] ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                  </button>
                </td>
              </tr>




              {isExpanded[index] && (
                <tr>
                  <td colSpan="6">
                    <div className="container">



                      <div className="row">
                        {/* New input for Unit Large Name */}
                        <div className="col-md-4">
                          <label>Unit Large</label>
                          <input
                            type="text"
                            className="form-control"
                            name="unit_large_name"
                            value={option.unit_large_name}
                            onChange={e => handlePriceOptionChange(index, e)}
                          />
                        </div>

                        {/* New input for Unit Small Name */}
                        <div className="col-md-4">
                          <label>Unit Small</label>
                          <input
                            type="text"
                            className="form-control"
                            name="unit_small_name"
                            value={option.unit_small_name}
                            onChange={e => handlePriceOptionChange(index, e)}
                          />
                        </div>

                        {/* New input for Conversion Factor */}
                        <div className="col-md-4">
                          <label>Conversion Factor to Small Unit</label>
                          <input
                            type="number"
                            className="form-control"
                            name="conversion_factor_to_small_unit"
                            value={option.conversion_factor_to_small_unit}
                            onChange={e => handlePriceOptionChange(index, e)}
                          />
                        </div>
                      </div>

                      <div className="row">
                        {/* Quantity and Unit combined input for Store */}
                        <div className="col-md-4">
                          <label>Store Quantity & Unit</label>
                          {renderQuantityUnitInput(option, index, 'store_quantity', 'store_unit_type')}
                        </div>

                        {/* Quantity and Unit combined input for Warehouse */}
                        <div className="col-md-4">
                          <label>Warehouse Quantity & Unit</label>
                          {renderQuantityUnitInput(option, index, 'warehouse_quantity', 'warehouse_unit_type')}
                        </div>

                        {/* Quantity and Unit combined input for Online */}
                        <div className="col-md-4">
                          <label>Online Quantity & Unit</label>
                          {renderQuantityUnitInput(option, index, 'online_quantity', 'online_unit_type')}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PriceOptions;
