
// /var/www/website2024/admin.siamweeds.com/myapp/src/components/MemberForm.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

// Import API_URLS from api.js
import { API_URLS } from '../api/api.js';

// A simple spinner component
const Spinner = () => (
  <div className="spinner-border text-primary" role="status">
    <span className="visually-hidden">Loading...</span>
  </div>
);

const MemberForm = ({ memberData, mode, setIsFormVisible, onTableRefresh, onUserSelect }) => {
  //console.log('Image URL:', memberData?.image);
  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const generateRandomPassword = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < 8; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return password;
  };


  const getInitialFormState = () => ({
    first_name: memberData?.first_name || '',
    sur_name: memberData?.sur_name || '',
    email: memberData?.email || '',
    telephone: memberData?.telephone || '',
    birthday: memberData?.birthday ? getFormattedDate(memberData.birthday) : '',
    contact: memberData?.contact || '',
    image: memberData?.image || '',
    branch_ID: memberData?.branch_ID || 6,  // Default to 6 (Online) if not provided
    position_ID: memberData?.position_ID || 1,  // Default to 1 (customer) if not provided
    password: generateRandomPassword() // Add random password generation
  });

  const [branches, setBranches] = useState([]);
  const [positions, setPositions] = useState([]);
  const initialImage = memberData?.image || '';
  const [formData, setFormData] = useState(getInitialFormState());
  const [submitStatus, setSubmitStatus] = useState({ success: false, message: '', loading: false });
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(memberData?.image || '');
  // Add a state to control the visibility of the spinner
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await fetch(API_URLS.BRANCHES_LIST, {  // Replace the hard-coded URL
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();
        setBranches(data);
      } catch (error) {
        console.error('Error fetching branches:', error);
      }
    };

    const fetchPositions = async () => {
      try {
        const response = await fetch(API_URLS.POSITION_LIST, {  // Replace the hard-coded URL
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();
        setPositions(data);
      } catch (error) {
        console.error('Error fetching positions:', error);
      }
    };

    fetchBranches();
    fetchPositions();
  }, []);



  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);

      // อัปเดตสถานะ previewImage ด้วย URL ของไฟล์ที่เลือก
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setPreviewImage(fileReader.result);
      };
    }
  };


  const uploadImage = async () => {
    const formData = new FormData();
    formData.append('uploadFile', selectedImage);
    formData.append('user_ID', memberData.user_ID);
    setSubmitStatus(prevStatus => ({ ...prevStatus, loading: true })); // เริ่ม Spinners

    try {
      const response = await fetch(API_URLS.IMAGE_UPLOAD, {  // Replace the hard-coded URL
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload image.');
      }

      const imageResponse = await response.json();
      console.log(imageResponse); // แสดง JSON ใน console

      // หน่วงเวลา 10 วินาทีก่อนตั้งค่า loading เป็น false
      setTimeout(() => {
        setSubmitStatus(prevStatus => ({ ...prevStatus, loading: false })); // หยุด Spinners
      }, 10000);

      return imageResponse;
    } catch (error) {
      console.error('Image upload failed:', error);
      setSubmitStatus({ success: false, message: 'Failed to upload image.', loading: false });
      throw error;
    }
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };


  const handleBranchChange = (e) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      branch_ID: e.value
    }));
  };

  const handlePositionChange = (e) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      position_ID: e.value
    }));
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Show spinner
    setSubmitStatus(prevStatus => ({ ...prevStatus, loading: true }));

    let updatedFormData = { ...formData };
    if (selectedImage) {
      try {
        const imageResponse = await uploadImage();
        updatedFormData.image = imageResponse.resizedFileURLs[0]; // ใช้ URL รูปภาพที่ปรับขนาดแล้ว
      } catch (error) {
        console.error('Image upload failed:', error);
        setSubmitStatus({ success: false, message: 'Failed to upload image.', loading: false });
        setIsSubmitting(false); // Set back to false here
        return;
      }
    }

     // ตรวจสอบโหมด ถ้าเป็น 'edit' ให้ลบฟิลด์ password ออกจากข้อมูลที่จะส่ง
    if (mode === 'edit') {
      delete updatedFormData.password;
    }

    const currentTime = new Date().toTimeString().split(' ')[0];
    const dataToSend = {
      ...updatedFormData,
      date: new Date().toISOString().split('T')[0],
      time: currentTime,
      ip: "192.168.1.15",
      user_ID: memberData?.user_ID  // ที่นี่เราเพิ่ม user_ID ในข้อมูลที่จะส่ง
    };

    const apiURL = mode === 'edit'
      ? API_URLS.EDIT_USER(memberData.user_ID)  // Replace the hard-coded URL
      : API_URLS.INSERT_USER;  // Replace the hard-coded URL

    // เพิ่มการดึง token จาก localStorage ที่นี่
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(apiURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // ใช้ token ที่ดึงมา
        },
        body: JSON.stringify(dataToSend),
      });

      console.log("Data to send:", dataToSend); // ตรวจสอบข้อมูลที่ส่งไปยัง API

      if (!response.ok) {
        const errorDetail = await response.json();
        console.error('API responded with:', errorDetail);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      console.log(result);
      setSubmitStatus({ success: true, message: 'Successfully Recorded!', loading: false });
      setIsSubmitting(false);
      onTableRefresh();

    } catch (error) {
      console.error('Error during API call:', error);
      setSubmitStatus({ success: false, message: 'Error: ' + error.message, loading: false });
      setIsSubmitting(false); // Set back to false here
    }
  };


  // ... (handleChange และฟิลด์แบบฟอร์ม)
  // Check if there is a selected image or not
  const isImageSelected = selectedImage !== null;


  return (
    <div>

      <form onSubmit={handleSubmit}>
        {/* ช่องอัปโหลดรูปภาพ */}


        {mode === 'edit' && (
          <div className="mb-3">
            {/* <p>Image URL: {memberData?.image}</p> */}
            <label htmlFor="imageInput" className="form-label">Photo</label>
            <input type="file" className="form-control" id="imageInput" onChange={handleImageChange} />
            {previewImage && <img src={previewImage} alt="Preview" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />}
          </div>
        )}

        {mode === 'add' && (
          <div className="mb-3 password-field">
            <label htmlFor="password" className="form-label">Password</label>
            <div className="input-group">
              <input
                type={passwordVisible ? "text" : "password"}
                className="form-control"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                autoComplete="current-password" // Improve security with autocomplete attribute
              />
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                <i className={passwordVisible ? "pi pi-eye-slash" : "pi pi-eye"}></i> {/* Toggle icon based on visibility */}
              </button>
            </div>
          </div>
        )}

        <div className="mb-3 d-flex">
          <div className="flex-grow-1 me-3">
            <label htmlFor="branch" className="form-label">Branch</label>
            <Dropdown
              value={formData.branch_ID}
              options={branches}
              onChange={handleBranchChange}
              optionLabel="branch_name"
              optionValue="branch_ID"
              placeholder="Select a Branch"
              className="w-100"
            />
          </div>

          {mode === 'add' && (
            <div className="flex-grow-1">
              <label htmlFor="position" className="form-label">Position</label>
              <Dropdown
                value={formData.position_ID}
                options={positions}
                onChange={handlePositionChange}
                optionLabel="position_Name"
                optionValue="position_ID"
                placeholder="Select a Position"
                className="w-100"
              />
            </div>
          )}
        </div>


        {/* ช่องชื่อ */}
        <div className="mb-3">
          <label htmlFor="firstName" className="form-label">First Name</label>
          <input type="text" className="form-control" name="first_name" value={formData.first_name} onChange={handleChange} required />
        </div>

        <div className="mb-3">
          <label htmlFor="sur_name" className="form-label">Surname</label>
          <input type="text" className="form-control" name="sur_name" value={formData.sur_name} onChange={handleChange} />
        </div>

        {/* ช่องวันเกิด */}
        <div className="mb-3">
          <label htmlFor="birthday" className="form-label">Birthday</label>
          <input type="date" className="form-control" name="birthday" value={formData.birthday} onChange={handleChange} required />
        </div>

        {/* ช่องติดต่อ */}
        <div className="mb-3">
          <label htmlFor="contact" className="form-label">Address</label>
          <input type="text" className="form-control" name="contact" value={formData.contact} onChange={handleChange} />
        </div>

        {/* ช่องโทรศัพท์ */}
        <div className="mb-3">
          <label htmlFor="telephone" className="form-label">Telephone</label>
          <input type="tel" className="form-control" name="telephone" value={formData.telephone} onChange={handleChange} required />
        </div>

        {/* ช่องอีเมล */}
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email</label>
          <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} required />
        </div>

        {/* ปุ่มส่งข้อมูล */}
        <div>
          <button type="submit" className="btn btn-success" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : mode === 'edit' ? 'Update Member' : 'Add Member'}
          </button>
          {isSubmitting && <Spinner />} {/* Show spinner when isSubmitting is true */}
        </div>
      </form>

      {submitStatus.message && (
        <div className={`alert ${submitStatus.success ? 'alert-success' : 'alert-danger'}`}>
          {submitStatus.message}
        </div>
      )}
    </div>
  );
};

export default MemberForm;