// /var/www/website2024/admin.siamweeds.com/myapp/src/data/mockDataBookingReport.js

import { API_URLS } from '../api/api.js';

const mockData_test = [
    // mock data
];

const employees_test = [
    { label: 'John Doe', value: 'E001' },
    { label: 'Jane Smith', value: 'E002' }
];

const customers_test = [
    { label: 'Alice', value: 'C001' },
    { label: 'Bob', value: 'C002' },
    // other customers
];

const branches_test = [
    { label: 'Mirabeau thaï spa', value: '1' },
    { label: 'Isaan Thaï Spa', value: '2' },
    { label: 'IsaanSpa', value: '3' }
];

const statuses = [
    { label: 'Confirmed', value: '5' },
    { label: 'Pending', value: '4' },
    { label: 'Cancelled', value: '6' }
];


const services_test = [
    { label: 'Hot Oil Massage', value: 'Hot Oil Massage' },
    { label: 'Cranial Massage', value: 'Cranial Massage' },
    { label: 'Hammam + Reflexology + Choice of Massage', value: 'Hammam + Reflexology + Choice of Massage' },
    { label: 'Traditional Thai Massage', value: 'Traditional Thai Massage' }
];

const types = [
    { label: 'Solo', value: 'Solo' },
    { label: 'Duo', value: 'Duo' }
];

const fetchEmployees = async () => {
    const userId = localStorage.getItem('user_ID');
    const url = API_URLS.ALL_USERS(userId, '2');
    const data = await fetchDataFromApi(url);
    return data.map(employee => ({
        label: employee.first_name,
        value: employee.user_ID
    }));
};

const fetchCustomers = async () => {
    const userId = localStorage.getItem('user_ID');
    const url = API_URLS.ALL_USERS(userId, '', '1');
    const data = await fetchDataFromApi(url);
    return data.map(customer => ({
        label: customer.first_name,
        value: customer.user_ID
    }));
};

const fetchDataFromApi = async (url) => {
    try {
        const response = await fetch(url, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        const contentType = response.headers.get('content-type');
        const statusCode = response.status;

        // Log the status code for better debugging
        console.log(`API Response Status: ${statusCode}`);

        // Check if the response is a valid JSON type
        if (!contentType || !contentType.includes('application/json')) {
            const errorText = await response.text(); // Get the full response content (HTML or other)
            console.error('Error: Expected JSON, but got HTML or other content:', errorText);
            
            // Additional checks for common status codes
            if (statusCode === 401) {
                console.error('Unauthorized request. Please check your authentication.');
            } else if (statusCode === 404) {
                console.error('API endpoint not found.');
            } else {
                console.error('Unknown error occurred. Status Code:', statusCode);
            }
            
            return []; // Return an empty array to avoid crashes
        }

        // Parse and return the JSON data
        const data = await response.json();
        return Array.isArray(data) ? data : [];
    } catch (error) {
        console.error('Error fetching data from API:', error);
        return []; // Return an empty array to handle API errors gracefully
    }
};


const fetchBranches = async () => {
    const data = await fetchDataFromApi(API_URLS.BRANCHES);
    return data.map(branch => ({
        label: branch.branch_name,
        value: branch.branch_ID
    }));
};

const fetchServices = async () => {
    const url = `${API_URLS.SERVICES_VIEW}?status=1`;  // Use SERVICES_VIEW with status=1
    console.log('Fetching services from URL:', url);  // Add this log for debugging

    const data = await fetchDataFromApi(url);
    console.log('Services data received:', data);  // Log the fetched data for debugging

    return data.map(service => ({
        label: service.ServiceName,
        value: service.ServiceID
    }));
};

const fetchMockData = async (filters) => {
    const currentDate = new Date();
    let startDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
    const endDate = new Date().toISOString().split('T')[0];

    if (filters.startDate) {
        startDate = filters.startDate.toISOString().split('T')[0];
    }

    const apiUrl = API_URLS.BOOKING_OVERVIEW(
        startDate,
        endDate,
        filters.BranchID,
        filters.ServiceIDMain,
        filters.BookingType,
        filters.EmployeeID,
        filters.CustomerID,
        filters.PaymentStatus,
        filters.BookingStatus
    );
    
    console.log('API URL:', apiUrl);  // Log the API URL

    try {
        const response = await fetch(apiUrl, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
            const errorText = await response.text();
            console.error('Error: Expected JSON, but got HTML or other content:', errorText);
            return [];
        }

        const data = await response.json();
        console.log('Fetched Data:', data);  // Log the fetched data
        return Array.isArray(data) ? data : [];
    } catch (error) {
        console.error('Error fetching booking report data:', error);
        return [];
    }
};


export { employees_test as employees, customers_test as customers, branches_test as branches, statuses, services_test as services, types, fetchEmployees, fetchCustomers, fetchBranches, fetchServices, fetchMockData };
