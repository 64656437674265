// /var/www/website2024/admin.siamweeds.com/myapp/src/pages/Receipt.js

import React, { useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import Sidebar from './Sidebar';
import Header from '../components/Header';
import PropTypes from 'prop-types';
import '../css/InvoiceComponent.scss';
import { API_URLS } from '../api/api';  // Import API URLs
import { Button } from 'primereact/button';  // Import PrimeReact Button

const AdminReceipt = () => {

    const [language] = useState('EN');
    const [translations, setTranslations] = useState({});
    const [fixedRate, setFixedRate] = useState({});
    const [storeConstants, setStoreConstants] = useState({});
    const [customerDetails, setCustomerDetails] = useState({});
    const [bookingDetails, setBookingDetails] = useState({});
    const [bookingDetailsExtended, setBookingDetailsExtended] = useState([]);
    const [loading, setLoading] = useState(false);  // Loading state for the button

    const [totalAmount, setTotalAmount] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);

    // Move useRef inside the component function
    const receiptRef = useRef();  // Reference for the receipt content

    const handlePrint = useReactToPrint({
        content: () => receiptRef.current,  // Points to the part you want to print
        documentTitle: 'Receipt',
        onBeforeGetContent: () => setLoading(true),  // Optional: Show a loading state
        onAfterPrint: () => setLoading(false),  // Optional: Hide the loading state after printing
    });


    useEffect(() => {
        const fetchBranchDetails = async () => {
            try {
                // Retrieve user_ID from localStorage, or set it if not available
                let userID = localStorage.getItem('user_ID');
                if (!userID) {
                    // Fetch and set user_ID in localStorage if not already present
                    const data = await fetchSomeUserAPI(); // Example API call to fetch user data
                    userID = data.user; // Assume data.user is the correct field
                    localStorage.setItem('user_ID', userID);
                }

                const branchResponse = await fetch(API_URLS.BRANCH_DETAILS(userID));  // Use userID from localStorage
                if (!branchResponse.ok) {
                    throw new Error(`Failed to fetch branch details with status: ${branchResponse.status}`);
                }

                const branchData = await branchResponse.json();
                if (branchData.length > 0) {
                    const branchInfo = branchData[0];
                    setStoreConstants({
                        name: branchInfo.branch_company,
                        address: branchInfo.location,
                        notes: branchInfo.branch_name,
                        phone: branchInfo.phone,
                        email: branchInfo.branch_email,
                        img: branchInfo.branch_img
                    });
                }
            } catch (error) {
                console.error("Fetching branch details failed:", error);
            }
        };

        fetchBranchDetails();
    }, []);




    const t = translations[language] || {};


    useEffect(() => {
        const fetchConfigData = async () => {
            try {
                const translationsResp = await fetch(API_URLS.TRANSLATIONS);
                const translationsData = await translationsResp.json();
                setTranslations(translationsData);

                const fixedRateResp = await fetch(API_URLS.FIXED_RATE);
                const fixedRateData = await fixedRateResp.json();
                //console.log("Fixed Rate Data:", fixedRateData); // Inspect fixedRateData
                setFixedRate(fixedRateData);

                const storeConstantsResp = await fetch(API_URLS.STORE_CONSTANTS);
                const storeConstantsData = await storeConstantsResp.json();
                setStoreConstants(storeConstantsData);
            } catch (error) {
                console.error("Fetching config data failed:", error);
            }
        };
        fetchConfigData();
    }, []);


    useEffect(() => {
        const fetchReceipt = async () => {
            const bookingID = localStorage.getItem('localBookingReduxID');
            const token = localStorage.getItem('token');

            try {
                // Fetch booking details using API_URLS
                const bookingResponse = await fetch(API_URLS.BOOKING_DETAILS(bookingID), {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!bookingResponse.ok) {
                    throw new Error(`HTTP error! status: ${bookingResponse.status}`);
                }

                const bookingData = await bookingResponse.json();
                if (bookingData.length > 0) {
                    setBookingDetails(bookingData[0]);
                    // Convert string values to numbers as necessary
                    const fetchedTotalAmount = parseFloat(bookingData[0].TotalAmount);
                    const fetchedAmountPaid = parseFloat(bookingData[0].AmountPaid);

                    setTotalAmount(fetchedTotalAmount);
                    setAmountPaid(fetchedAmountPaid);


                    // Fetching customer details
                    const customerID = bookingData[0].CustomerID;
                    const customerResponse = await fetch(API_URLS.CUSTOMER_DETAILS(customerID), {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    //console.log('Customer Response:', customerResponse);

                    if (!customerResponse.ok) {
                        console.error('Customer response was not ok:', customerResponse.status);
                        // Optionally, you can throw an error here to stop the execution and catch it below
                        throw new Error(`HTTP error! status: ${customerResponse.status}`);
                    }

                    const customerData = await customerResponse.json();
                    //console.log('Customer Data:', customerData);
                    setCustomerDetails(customerData);

                    // Fetch extended booking details
                    const detailResponse = await fetch(API_URLS.BOOKING_DETAILS_EXTENDED(bookingID));
                    if (!detailResponse.ok) {
                        throw new Error(`Failed to fetch booking details with status: ${detailResponse.status}`);
                    }

                    const bookingDetailsJson = await detailResponse.json();
                    //console.log('Booking Details Json:', bookingDetailsJson);

                    const extendedDetails = await Promise.all(bookingDetailsJson.map(async (detail) => {
                        const priceResponse = await fetch(API_URLS.SERVICE_PRICE(detail.ServiceID));
                        if (!priceResponse.ok) {
                            throw new Error(`Failed to fetch price details for ServiceID ${detail.ServiceID}`);
                        }
                        const priceJson = await priceResponse.json();
                        //console.log("Price details for ServiceID:", detail.ServiceID, priceJson);
                        return {
                            ...detail,
                            ServiceName: priceJson.results[0].ServiceName,
                            PriceType: priceJson.results[0].PriceType,
                            PriceValue: priceJson.results[0].PriceValue
                        };
                    }));

                    setBookingDetailsExtended(extendedDetails);

                    //const taxRate = fixedRate.tax || 0; // Use a default value of 0 if the tax rate is undefined
                    const tax = fetchedTotalAmount * (fixedRate.tax || 0) / 100;

                    //console.log('fixedRate.tax:', fixedRate.tax);
                    //console.log('tax:', tax);    
                }
            } catch (error) {
                console.error("Fetching invoices failed:", error);
            }
        };

        fetchReceipt();
    }, []); // Ensure the dependency array is correctly set for your use case

    // Define the formatDate function
    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };


    // if (!invoices || !invoices.length) {
    //     return <div>Loading...</div>;
    // }

    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboard-main">
                <Header />

                <Button
                    label="Download PDF"
                    icon="pi pi-file-pdf"
                    className="print-button"
                    loading={loading} 
                    onClick={handlePrint}
                />

                <div className="content-area">

                    <div ref={receiptRef} className="invoice-container">
                        {/* Header Section */}
                        <header className="invoice-header">
                            {/* Company Logo */}
                            <img src={storeConstants?.img} alt="invoice Logo" className="invoice-logo" />
                            {/* RECEIPT Title */}
                            <h1>{t['RECEIPT']}</h1> {/* Use the translated RECEIPT title */}
                        </header>

                        {/* Client Details Section */}
                        <section className="client-details">
                            {/* Client Information */}

                            <div className='text-left'>
                                <strong>{t['RECEIPT_TO']}</strong>
                                <p>{customerDetails?.first_name} {customerDetails?.sur_name}</p>

                                <div className='line-index'></div>

                                <strong>{t['CONTACT_PERSON']}</strong>

                                <p className='bottom-unset'>{t['PHONE']}: {customerDetails?.telephone}</p>
                                <p className='bottom-unset'>{t['EMAIL']}: {customerDetails?.email}</p>
                                <p>{t['ADDRESS']}: {customerDetails?.contact}</p>

                            </div>

                            {/* RECEIPT Information */}
                            <div className='text-left'>
                                <p className='bottom-unset'>{t['RECEIPT_NO']}: {bookingDetails?.BookingID}</p>
                                <p>{t['RECEIPT_DATE']}: {formatDate(bookingDetails?.date)}</p>

                                <div className='line-index'></div>
                                <strong> {t['PAYMENT_METHOD']} </strong>
                                <p className='bottom-unset'>{t['ORDER_ID']}: {bookingDetails?.BookingID}</p>
                                <p className='bottom-unset'>{t['ACCOUNT_NAME']}: {customerDetails?.first_name} {customerDetails?.sur_name} </p>
                                <p> {t['STATUS']}: {bookingDetails?.PaymentStatusName}</p>

                            </div>
                        </section>

                        {/* RECEIPT Items Section */}
                        <table className="invoice-items">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{t['DESCRIPTION']}</th>
                                    <th>{t['QUANTITY']}</th>
                                    <th>{t['SERVICE']}</th>
                                    <th>{t['PRICE']}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bookingDetailsExtended.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.ServiceName || 'N/A'}</td> {/* แสดง 'N/A' ถ้า ServiceName เป็น undefined */}
                                        <td>{`${new Date(item.ServiceDate).toLocaleDateString()} ${item.ServiceTime}`}</td> {/* รูปแบบวันที่และเวลา */}
                                        <td>{item.PriceType || 'N/A'}</td> {/* แสดง 'N/A' ถ้า PriceType เป็น undefined */}
                                        <td>{item.BookingDetailPrice ? `${item.BookingDetailPrice} €` : 'N/A'}</td> {/* แสดง 'N/A' ถ้า PriceValue เป็น undefined */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className="row mt-4">

                            <div className="col-6">
                                <img src={storeConstants?.img} alt="invoice Logo" className="footer-logo" />
                                <p className='bottom-unset'>{storeConstants?.name}</p> {/* Removed clientDetails.name fallback */}
                                <p>{storeConstants?.address}</p> {/* Removed clientDetails.address fallback */}
                                <div className='line-index'></div>
                                <p>{storeConstants?.notes}</p> {/* Removed clientDetails.notes fallback */}
                            </div>



                            <div className="col-3">
                                <p className="grid-item sub-total-label">{t['SUB_TOTAL']}</p>
                                <p className="grid-item sub-total-label">{t['TAX']} ({fixedRate.tax}%)</p>
                                <p className="grid-item sub-total-label">{t['TOTAL_TRA']} </p>
                            </div>

                            <div className="col-3">
                                {/* <p className='grid-item'>{totalAmount.toFixed(2)}</p> */}
                                {/* <p className='grid-item'>{totalAmount.toFixed(2)-((totalAmount * (fixedRate.tax || 0) / 100) || 0).toFixed(2)}</p> */}

                                <p className='grid-item'>{totalAmount.toFixed(2)}</p>

                                <p className='grid-item'>{((amountPaid * (fixedRate.tax || 0) / 100) || 0).toFixed(2)}</p>

                                <p className='grid-item'>{amountPaid.toFixed(2)}</p>
                            </div>
                        </div>

                        <div className='line-index'></div>
                        <div className='mt-2' >
                            <div className="legal-notes text-left">
                                <strong >{t['NOTES_IMPORTANTES']} :</strong>
                                <p> {t['NOTES_IMPORTANT']}</p>
                            </div>

                            <div className="row mt-4">
                                <div className="col-5">
                                    <p className="icon-text">
                                        <i className="pi pi-map-marker large-icon"></i>
                                        <span>{storeConstants.address}</span>
                                    </p>
                                </div>

                                <div className="col-3">
                                    <p className="icon-text">
                                        <i className="pi pi-phone large-icon"></i>
                                        <span>{storeConstants.phone}</span>
                                    </p>
                                </div>

                                <div className="col-4">
                                    <p className="icon-text">
                                        <i className="pi pi-envelope large-icon"></i>
                                        <span>{storeConstants.email}</span>
                                    </p>
                                </div>
                            </div>

                            <div className='line-index'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};



// Prop types validation
AdminReceipt.propTypes = {

    language: PropTypes.string, // Assuming language is a string prop
};

export default AdminReceipt;
