// /var/www/website2024/admin.siamweeds.com/myapp/src/components/DataQueues/createEventObject.js

const createEventObject = (event) => {
  // Ensure ServiceDate and ServiceTime are defined
  const serviceDateTimeString = event.ServiceDate && event.ServiceTime
      ? `${event.ServiceDate.split(' ')[1]} ${event.ServiceDate.split(' ')[2]} ${event.ServiceDate.split(' ')[3]} ${event.ServiceTime} GMT`
      : 'Invalid Date';

  const eventDateTime = new Date(serviceDateTimeString);

  // Function to add leading zero if necessary
  const addLeadingZero = (time) => {
    const [hours, minutes, seconds] = time.split(':');
    const formattedHours = hours.length === 1 ? `0${hours}` : hours;
    return `${formattedHours}:${minutes}:${seconds || '00'}`; // Ensure seconds exist, default to '00'
  };

  const formattedStartTime = event.ServiceTime ? addLeadingZero(event.ServiceTime) : 'Unknown';
  const formattedEndTime = event.EndTime ? addLeadingZero(event.EndTime) : 'Unknown';
  const amountDue = (event.PriceValue && event.AmountPaid) ? event.PriceValue - event.AmountPaid : 0;

  return {
    startTime: formattedStartTime,
    endTime: formattedEndTime,
    service: event.ServiceName || 'Unknown Service',
    customer: `${event.EmployeeFirstName || ''} ${event.EmployeeSurName || ''}`.trim(),
    resourceId: event.EmployeeID ? event.EmployeeID.toString() : 'Unknown',
    extendedProps: {
      serviceDetail: event.PriceType || 'Unknown',
      date: eventDateTime.toLocaleDateString() || 'Unknown Date',
      time: formattedStartTime,
      endtime: formattedEndTime,
      customerInfo: `${event.CustomerFirstName || ''} ${event.CustomerSurName || ''}, ${event.CustomerTelephone || 'N/A'}`.trim(),
      customerTel: event.CustomerTelephone || 'N/A',
      employeeInfo: `${event.EmployeeFirstName || ''} ${event.EmployeeSurName || ''}, ${event.EmployeeTelephone || 'N/A'}`.trim(),
      price: `${event.PriceValue || 0} ${event.symbol || ''}`,
      amountPaid: `${event.AmountPaid || 0} ${event.symbol || ''}`,
      amountDue: `${amountDue.toFixed(2)} ${event.symbol || ''}`,
      notes: `Notes: ${event.note || 'None'}`,
      serviceStatus: event.ServiceStatus || 'Unknown',
      bookingType: event.BookingType || 'Unknown',
      BookingID: event.BookingID || 'Unknown',
      bookingDetailId: event.BookingDetailID || 'Unknown'
    },
  };
};

export default createEventObject;
