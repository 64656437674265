// /var/www/website2024/admin.siamweeds.com/myapp/src/components/EventDialog/DateOfService.js

import React, { useEffect } from 'react';
import { InputText } from 'primereact/inputtext';

const DateOfService = ({ selectedDateEvent, setSelectedDateEvent }) => {

    useEffect(() => {
        const currentDate = new Date().toISOString().split('T')[0];
        setSelectedDateEvent(currentDate);
    }, [setSelectedDateEvent]);

    const logSelectedDate = (date) => {
        console.log("Selected Date:", date);
    };

    return (
        <div>
            <label>Date Of Service</label>
            <span className="p-input-icon-right">
                <InputText
                    type="date"
                    value={selectedDateEvent}
                    onChange={(e) => {
                        const newDate = e.target.value;
                        setSelectedDateEvent(newDate);
                        logSelectedDate(newDate);
                    }}
                    className=""
                    onClick={(e) => e.target.showPicker && e.target.showPicker()}
                />
            </span>
        </div>
    );
};

export default DateOfService;
