///var/www/website2023/py.testproject.work/s-293-spatime-admin/src/pages/Sidebar.js
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import '../css/Sidebar.css'; // Ensure this path is correct for your CSS

const Sidebar = () => {
  const [activeMenu, setActiveMenu] = useState('dashboard');

  const userProfile = {
    name: 'PURPLE LION PACIFIC',
    role: 'Weeds Walk CRM Management System',
    image: 'https://afaa.website/s/3dc61b.svg', // Replace with path to user's image
  };

  // Handle image loading error
  const handleImageError = (e) => {
    e.target.onerror = null; // Prevents infinite callback loop
    e.target.src = 'fallback-image-path'; // Replace with a fallback image path
  };

  const menuItems = [
    { name: 'Dashboard', icon: 'fa-tachometer-alt', path: '/Dashboard' },
    { name: 'Payment', icon: 'fa-credit-card', path: '/payment' },
    { name: 'Order', icon: 'fa-calendar-check', path: '/booking' },
    { name: 'Queues', icon: 'fa-people-group', path: '/queues' },
    { name: 'Staff', icon: 'fa-users', path: '/Member' },
    { name: 'Product', icon: 'fa-spa', path: '/services' },
    { name: 'Type Product', icon: 'fa-solid fa-layer-group', path: '/typeservices' },
    {
      name: 'Reports', icon: 'fa-file-invoice', path: '#', subItems: [
        { name: 'Order',  icon: 'fa-file-invoice',  path: '/report/booking' },
        // { name: 'Payment', icon: 'fa-file-invoice', path: '/report/payment' },
        // { name: 'Service', icon: 'fa-file-invoice', path: '/report/service' },
        // { name: 'Customer', icon: 'fa-file-invoice', path: '/report/customer' }
      ]
    },
    { name: 'Logout', icon: 'fa-sign-out-alt', path: '/logout' }
  ];


  const handleMenuClick = (menuName) => {
    setActiveMenu(menuName);
    // Implement routing or state change as necessary
  };

  const renderSubItems = (subItems) => {
    return (
      <ul className="submenu">
        {subItems.map(subItem => (
          <li key={subItem.name} className="submenu-item">
            <a 
              href={subItem.path} 
              className="menu-item"
              onClick={() => handleMenuClick(subItem.name)}
            >
              <i className={`fas ${subItem.icon}`}></i> {subItem.name}
            </a>
          </li>
        ))}
      </ul>
    );
  };
  
  

  return (
    <aside className="sidebar">
      {/* User Profile Section */}
      <div className="user-profile">
        <img
          src={userProfile.image}
          alt={userProfile.name}
          className="user-image"
          onError={handleImageError} // Handle the image loading error
        />
        <div className="user-info text-center ">
          <h5 className="user-name">{userProfile.name}</h5>
          <p className="user-role">{userProfile.role}</p>
        </div>
      </div>
  
      <nav className="menu">
        {menuItems.map(item => (
          <div key={item.name}>
            <a
              href={item.path}
              className={`menu-item ${activeMenu === item.name ? 'active' : ''}`}
              onClick={() => handleMenuClick(item.name)}
            >
              <i className={`fas ${item.icon}`}></i> {item.name}
            </a>
            {item.subItems && renderSubItems(item.subItems)}
          </div>
        ))}
      </nav>
    </aside>
  );
};

export default Sidebar;
