// /var/www/website2024/admin.siamweeds.com/myapp/src/components/EventDialog/ServiceTypeSidebar.js

import React, { useEffect, useState } from 'react';
import { Sidebar, Button, InputSwitch } from 'primereact';
import { useDispatch } from 'react-redux';
import ToastNotification from '../ToastNotification'; // Import the ToastNotification component
import { setSidebarVisible } from '../../redux/slices/sidebarSlice';

const resetServiceTypeSelections = () => {
  console.log("Service type selections reset.");
};

const ServiceTypeSidebar = ({
  visible,
  onHide,
  priceData,
  priceSelections,
  handleInputSwitchChange,
  setTopSidebarVisible,
  refreshServiceTimes, // Add refreshServiceTimes prop  
  toggleTopSidebarVisible
}) => {
  const [showToast, setShowToast] = useState(false); // State for toast visibility
  const [toastMessage, setToastMessage] = useState(''); // State for toast message
  const [toastVariant, setToastVariant] = useState('info');
  const dispatch = useDispatch();


  const handleSwitchChange = (priceID, priceValue) => {
    console.log('Calling handleInputSwitchChange with:', { priceID, priceValue });
    handleInputSwitchChange(priceID, priceValue);
    //console.log('maxServiceTimes:', maxServiceTimes); // Log maxServiceTimes
    refreshServiceTimes(); // Refresh service times after switch change
  };

  useEffect(() => {
    if (visible) {
      setToastMessage('Service Type Sidebar is now visible');
      setToastVariant('info');
      setShowToast(true);
      dispatch(setSidebarVisible(true)); // Set sidebar visibility to true in Redux
    } else {
      dispatch(setSidebarVisible(false)); // Set sidebar visibility to false in Redux
    }
  }, [visible, dispatch]);



  return (
    <>

      <ToastNotification
        showToast={showToast}
        toastMessage={toastMessage}
        toastVariant={toastVariant}
        setShowToast={setShowToast}
      />

      <Sidebar
        visible={visible}
        position="left"
        onHide={() => {
          onHide();
          resetServiceTypeSelections(); // Reset InputSwitch values
          refreshServiceTimes(); // Refresh service times on hide
        }}
      >
        <h3>Service Type</h3>


        <Button
          label="Toggle topSidebarVisible"
          onClick={() => {
            console.log("Toggling sidebar...");
            toggleTopSidebarVisible();  // Call the toggle function instead of setting visibility manually
          }}
          className="p-mt-3"
        />



        {/* <Button icon="pi pi-arrow-right" onClick={() => setRightSidebarVisible(true)} /> */}

        {priceData.map((priceInfo) => (
          <div key={priceInfo.PriceID}>
            <h4>{priceInfo.PriceType}</h4>
            <div className="switch-wrapper">
              <InputSwitch
                checked={priceSelections[priceInfo.PriceID]}
                onChange={() => handleSwitchChange(priceInfo.PriceID, priceInfo.PriceValue)} // Call the local handler
                style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 0 }}
              />
              <span className="switch-label">
                {priceInfo.PriceValue}{priceInfo.symbol}
              </span>
            </div>
          </div>
        ))}


      </Sidebar>
    </>
  );
};

export default ServiceTypeSidebar;
