// /var/www/website2024/admin.siamweeds.com/myapp/src/components/EventDialog/CustomerSearch.js
import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { OverlayPanel, InputText, DataTable, Column } from 'primereact';
import styles from '../../css/OverlayPanel.module.scss';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import MemberForm from '../MemberForm';

import { API_URLS } from '../../api/api.js';  // Adjust the import path if necessary

const CustomerSearch = ({ userID = 4380, onUserSelect }) => {
    const [userData, setUserData] = useState([]);
    const searchInputRef = useRef(null);
    const op = useRef(null);

    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [formMode, setFormMode] = useState('add'); // For form mode
    const [currentMember, setCurrentMember] = useState(null); // For passing member data to form

    // Fetch data when the component is first rendered or when user clicks the InputText
    const fetchUserData = async () => {
        const apiUrl = API_URLS.ALL_USERS(userID);  // Adjusted to fetch all users, or modify the API call as needed
        try {
            const response = await axios.get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            });
            setUserData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Open OverlayPanel on click
    const handleInputClick = async () => {
        // Fetch user data if it's not already loaded
        if (userData.length === 0) {
            await fetchUserData();
        }
        op.current.toggle(searchInputRef.current); // Show the OverlayPanel on click
    };

    const handleUserSelect = (user) => {
        onUserSelect(user);
        op.current.hide();
    };

    return (
        <div>
            <label>Customers</label>
            <span className="p-input-icon-right">
                <i className="pi pi-search" />
                <InputText
                    ref={searchInputRef}
                    onClick={handleInputClick} // Changed to handle the click event
                    placeholder="Click to select a customer"
                />
            </span>

            <Button
                icon="pi pi-plus"
                className="p-button-success"
                onClick={() => setIsDialogVisible(true)}
            />

            <OverlayPanel
                ref={op}
                dismissable
                showCloseIcon
                id="overlay_panel"
                className={styles.overlayPanelCustom}
                target={searchInputRef.current}
            >
                <DataTable value={userData} selectionMode="single" onSelectionChange={e => handleUserSelect(e.value)}>
                    <Column field="first_name" header="First Name" />
                    <Column field="sur_name" header="Surname" />
                    <Column field="telephone" header="Telephone" />
                    <Column field="email" header="Email" />
                </DataTable>
            </OverlayPanel>

            <Dialog
                header="Add Member"
                visible={isDialogVisible}
                style={{ width: '50vw' }}
                onHide={() => setIsDialogVisible(false)}
                className={styles.PanelMemberForm}
            >
                <MemberForm
                    memberData={currentMember}
                    mode={formMode}
                    setIsFormVisible={setIsDialogVisible}
                    onTableRefresh={() => fetchUserData()}
                    onUserSelect={handleUserSelect} // Pass handleUserSelect to MemberForm
                />
            </Dialog>
        </div>
    );
};

export default CustomerSearch;
