///var/www/website2023/py.testproject.work/s-293-spatime-admin/src/pages/ChangePassword.js

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './Sidebar'; // ตรวจสอบ path ที่ถูกต้อง
import Header from '../components/Header.js'; // ตรวจสอบ path ที่ถูกต้อง
import '../css/change_password.css';


const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');


  // ใช้ Spinner จาก MemberForm
  const Spinner = () => (
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );

  // ใช้ state และ logic ที่คล้ายกับใน MemberForm
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ success: false, message: '', loading: false });


  const handleChangePassword = async () => {
    setIsSubmitting(true);
    if (password !== confirmPassword) {
      setMessage('Password and confirm password do not match.');
      setIsSubmitting(false);
      return;
    }
  
    const url = `https://py-maracar.afaa.website/register/api/change_password?user_ID=${userId}`;
    const token = localStorage.getItem('token');
  
    try {
      const response = await fetch(url, {
        method: 'PUT', // เปลี่ยนเป็น PUT
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          current_password: currentPassword,
          new_password: password
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to change password.');
      }
  
      setMessage('Password changed successfully.');
    } catch (error) {
      console.error('Error changing password:', error);
      setMessage('Failed to change password. Please try again later.');
    }
    setIsSubmitting(false);
  };
  


  return (
    <div className="dashboard">
      <Sidebar />
      <div className="dashboard-main">
        <Header />
        <div className="change-password-container">

          <h2>Change Password</h2>

          <div className="mb-3">
            <label htmlFor="currentPassword" className="form-label">Current Password:</label>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              id="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="password" className="form-label">New Password:</label>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="confirmPassword" className="form-label">Confirm Password:</label>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>

          <div className="mb-3">
  <button className="btn btn-outline-secondary" type="button" onClick={() => setShowPassword(!showPassword)}>
    <i className={showPassword ? "fa-solid fa-eye-slash" : "fa-regular fa-eye"}></i>
  </button>
</div>



          <div className="mb-3">
            <button className="btn btn-primary" onClick={handleChangePassword}>Change Password</button>
          </div>
          {message && <p>{message}</p>}


        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
