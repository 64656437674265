// /var/www/website2024/admin.siamweeds.com/myapp/src/pages/Typeservices.js

import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import Header from '../components/Header';
import TypeservicesTable from '../components/TypeservicesTable';

import 'bootstrap/dist/css/bootstrap.min.css';
import TypeServicesForm from '../components/TypeServicesForm';
import '../css/TypeServicesForm.css'; // นำเข้า CSS ที่กำหนดเอง

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { API_ENDPOINTS } from '../api/api.js';

const TypeServices = () => {
  const [typeServicesData, setTypeServicesData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [currentTypeService, setCurrentTypeService] = useState(null);
  const [formMode, setFormMode] = useState('add'); // Track the form mode: 'add' or 'edit'
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // Use global userID
  const userID = localStorage.getItem('user_ID');




  // Common headers for API calls
  const commonHeaders = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
  };

  // Modify this function
  const handleDelete = async (serviceTypeId) => {
    setIsDeleting(true); // เริ่มการแสดง spinner
    // Prepare the body based on whether we have an array of IDs or a single ID
    const bodyContent = Array.isArray(serviceTypeId)
      ? JSON.stringify(serviceTypeId)
      : JSON.stringify([serviceTypeId]); // Wrap single ID in an array

    try {
      const response = await fetch(API_ENDPOINTS.TYPES_DELETE, {
        method: 'DELETE',
        headers: commonHeaders,
        body: bodyContent,
      });

      if (!response.ok) {
        throw new Error('Failed to delete service type');
      }
      // Refresh data after deletion
      refetchTypeServicesData();
    } catch (error) {
      console.error('Error deleting service type:', error);
    } finally {
      setIsDeleting(false); // หยุดการแสดง spinner
    }
  };

  const handleEdit = (data) => {
    setCurrentTypeService(data);
    setFormMode('edit');
    setIsFormVisible(true);
  };

  const handleCancelEdit = () => {
    setIsFormVisible(false); // This should change the state and trigger a re-render
  };

  const handleAdd = () => {
    setCurrentTypeService(null);
    setFormMode('add');
    setIsFormVisible(true);
  };

  const handleSave = async (formData) => {
    setIsSaving(true); // เริ่มการแสดง spinner
    const apiURL = formMode === 'edit'
      ? API_ENDPOINTS.TYPES_EDIT
      : API_ENDPOINTS.TYPES_CREATE;

    // แปลง StatusName เป็น status ID
    //formData.status = parseInt(formData.status);

    const dataToBeSent = {
      ...formData,
      status: parseInt(formData.status, 10), // แปลงเป็นตัวเลข
      user_ID: userID
    };


    try {
      const response = await fetch(apiURL, {
        method: formMode === 'edit' ? 'PUT' : 'POST',
        headers: commonHeaders,
        body: JSON.stringify(dataToBeSent)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      console.log("Service type saved successfully");
      setIsFormVisible(false);
      refetchTypeServicesData();
    } catch (error) {
      console.error('Failed to save service type:', error);

    } finally {
      setIsSaving(false); // หยุดการแสดง spinner
    }
  };




  const handleRefresh = () => {
    // Refresh the data
    // ...
  };


  const [statusOptions, setStatusOptions] = useState([]);


  // Function to refetch type services data
  const refetchTypeServicesData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(API_ENDPOINTS.SERVICES_VIEW_TYPE, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setTypeServicesData(data);
    } catch (error) {
      console.error('Fetch error:', error);
    }


  };



  // Modify the fetchStatusOptions function
  const fetchStatusOptions = async () => {
    try {
      const response = await fetch(API_ENDPOINTS.STATUS_LIST);
      if (!response.ok) throw new Error('Error fetching status options');
      const data = await response.json();
      setStatusOptions(data);
    } catch (error) {
      console.error('Error fetching status options:', error);
    }
  };

  useEffect(() => {
    refetchTypeServicesData();
    fetchStatusOptions();
  }, []);

  return (
    <div className="dashboard">
      <Sidebar />
      <div className="dashboard-main">


        <Header
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          placeholderText="Type Services in to search..."
          onRefresh={handleRefresh}
          onBack={isFormVisible ? handleCancelEdit : undefined}
          isEditMode={isFormVisible} // Pass whether the form is visible (edit mode)
        />

        {isDeleting && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}

        {isSaving && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}


        {isFormVisible ? (
          <TypeServicesForm
            typeServiceData={currentTypeService}
            mode={formMode}
            onCancel={handleCancelEdit}
            onSave={handleSave}
            onRefresh={refetchTypeServicesData}
            statusOptions={statusOptions}
          />
        ) : (
          <>
            <div className="member-table-container">

              <div className="d-grid gap-2 d-md-flex justify-content-md-end">

                <div className="select-container me-md-2">
                  <select id="pageLengthSelect" className="form-select" aria-label="Select page length">
                    <option value="10">10 Rows</option>
                    <option value="20">20 Rows</option>
                    <option value="50">50 Rows</option>
                  </select>
                </div>
                <button onClick={handleAdd} className="btn btn-dark btn-sm"> <FontAwesomeIcon icon={faPlus} /> Create New Type</button>

              </div>

              <TypeservicesTable typeServicesData={typeServicesData} searchQuery={searchQuery} onEdit={handleEdit} onDelete={handleDelete} />
            </div>


          </>

        )}
      </div>
    </div>
  );
};

export default TypeServices;
