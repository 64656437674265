// /var/www/website2024/admin.siamweeds.com/myapp/src/components/DataQueues/EventDialogServiceTimeSidebar.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import { useDispatch } from 'react-redux';
import { Sidebar, Button, ProgressSpinner } from 'primereact';
import { Row, Col } from 'react-bootstrap';
import '../../css/EventDialogServiceTimeSidebar.scss';
import bankInfo from '../../data/bankInfo.json'; // Adjust the path as needed

import PaymentDetails from '../../components/DataQueues/EventDialogServiceTimeSidebar/PaymentDetails';  // Import the new PaymentDetails 
import InputSwitchWrapper from '../../components/DataQueues/EventDialogServiceTimeSidebar/InputSwitchWrapper';  // Import the new 
import PaymentMethodContainer from '../../components/DataQueues/EventDialogServiceTimeSidebar/PaymentMethodContainer';
import PaymentLabels from '../../components/DataQueues/EventDialogServiceTimeSidebar/PaymentLabels';  // Import the new PaymentLabels component

import { API_URLS } from '../../api/api';  // Adjust the path as needed


const EventDialogServiceTimeSidebar = ({
  isSidebarVisible,
  onHide,
  toggleTopSidebarVisible,
  selectedDate,
  priceID,
  fetchReserveServiceTimes,
  cumulativePrice,
  handleSubmit,
  hideServiceTypeSidebar,
  onHideDialog,
  refreshParentData,
  clearCumulativePrice
}) => {
  const dispatch = useDispatch();
  const [reserveServiceTimes, setReserveServiceTimes] = useState([]);

  const [topSidebarVisible, setTopSidebarVisible] = useState(false);

  const [paymentMethodGroup1, setPaymentMethodGroup1] = useState('payCash'); // Group 1: visa, payCash, qrCode
  const [paymentMethodGroup2, setPaymentMethodGroup2] = useState('store'); // Group 2: online, store


  const [paymentPercentage, setPaymentPercentage] = useState('full');
  const [paymentAmount, setPaymentAmount] = useState(cumulativePrice);
  const [loading, setLoading] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true); // New state variable
  const [isRefreshing, setIsRefreshing] = useState(false); // New state variable

  const [paymentDetails, setPaymentDetails] = useState({ type: '', details: {} });
  const sidebarContentRef = useRef(null);

  // ปรับปรุงฟังก์ชัน handlePaymentPercentageChange
  const handlePaymentPercentageChange = (percentage) => {
    setPaymentPercentage(percentage);
    setPaymentAmount(percentage === 'half' ? cumulativePrice / 2 : (percentage === 'full' ? cumulativePrice : 0));
  };

  useEffect(() => {
    console.log("topSidebarVisible changed:", isSidebarVisible);
    
    if (isSidebarVisible) {
      console.log("Sidebar is open");
      // Perform any additional logic required when sidebar opens
    } else {
      console.log("Sidebar is closed");
      // Perform any additional logic required when sidebar closes
    }
  }, [isSidebarVisible]);


  useEffect(() => {

    if (isSidebarVisible && shouldFetch) {
      setPaymentMethodGroup1('payCash'); // Set default payment method to Pay Cash
      loadPaymentDetails('payCash');
      setIsRefreshing(true); // Start the spinner      
      fetchReserveServiceTimes(selectedDate, priceID, (times) => {
        //console.log("API Data:", times); // Print fetched data from API
        simulateAvailableTimes(times);  // Simulate the available times on sidebar visibility
        setIsRefreshing(false); // Stop the spinner
      });
      setShouldFetch(false); // Prevent fetching again until needed
      setTopSidebarVisible(true);  // Automatically open the bottom sidebar on loa
    }

  }, [isSidebarVisible, selectedDate, priceID, fetchReserveServiceTimes, shouldFetch]);



  useEffect(() => {
    if (topSidebarVisible) {
      setPaymentAmount(cumulativePrice);
      scrollToBottom(); // Scroll to bottom when the sidebar becomes visible
    }
  }, [topSidebarVisible, cumulativePrice]);

  // Function to simulate available times based on API data
  const simulateAvailableTimes = (times) => {
    const simulatedTimes = times.map((slot) => {
      const isAvailable = slot.statusHH === "Available";
      return { ...slot, statusHH: isAvailable ? "Available" : "Unavailable", disabled: isAvailable };
    });
    setReserveServiceTimes(simulatedTimes);
  };

  // Corrected state reset to ensure only user-selected slots are reset
  const resetServiceTimeSelections = () => {
    const resetTimes = reserveServiceTimes.map(slot => ({
      ...slot,
      statusHH: slot.statusHH !== "Available" && !slot.disabled ? "Unavailable" : slot.statusHH
    }));
    setReserveServiceTimes(resetTimes);
    sessionStorage.setItem('tempReserveServiceTimes', JSON.stringify(resetTimes));
  };

  const handlePaymentMethodChangeGroup1 = (method) => {
    setPaymentMethodGroup1(method);
    setPaymentAmount(paymentPercentage === 'half' ? cumulativePrice / 2 : cumulativePrice);
    if (['payCash', 'qrCode'].includes(method)) loadPaymentDetails(method);
  };

  const handlePaymentMethodChangeGroup2 = (method) => {
    setPaymentMethodGroup2(method);
  };

  const loadPaymentDetails = (method) => {
    const details = method === 'payCash' ? bankInfo.payCash : bankInfo.qrCode;
    setPaymentDetails({ type: method, details });
  };

  const handlePaymentAmountChange = (value) => setPaymentAmount(value.replace(/\D/g, ''));
  const handleInputFocus = () => setPaymentAmount('');
  const handleInputBlur = (e) => { if (!e.target.value) setPaymentAmount(cumulativePrice); };

  const generateReferenceCode = () => {
    return [...Array(32)].map(() => Math.random().toString(36)[2]).join('');
  };


  const preparePaymentData = (referenceNumber, bookingID, customerID, employeeID, amount, remainingAmount, notes) => {
    const data = [
      {
        ReferenceNumber: referenceNumber,
        TransactionDate: new Date().toISOString(),
        BookingID: bookingID,
        ExpenseTypeID: 9,
        CustomerID: customerID,
        EmployeeID: employeeID,
        Amount: amount,
        Currency: "THB",
        Remaining: remainingAmount,
        PaymentMethod: "5",
        PaymentStatus: "7",
        Status: "4",
        Notes: notes
      }
    ];
    console.log('Prepared Payment Data:', data); // Log prepared payment data
    return data;
  };

  const fetchLatestBookingData = async () => {
    try {
      const response = await axios.get(API_URLS.EVENTS, {
        params: {
          date_from: new Date().toISOString().split('T')[0],
          date_to: new Date().toISOString().split('T')[0]
        },
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      const bookingData = response.data;
      console.log('Fetched Latest Booking Data:', bookingData);

      // Transform and prepare the payment data for the most recent booking
      if (Array.isArray(bookingData) && bookingData.length > 0) {
        const latestBooking = bookingData[0];
        const referenceCode = generateReferenceCode();
        const notes = `${paymentMethodGroup2 === 'store' ? 'In-Store' : 'Online'}, ${paymentMethodGroup1 === 'payCash' ? 'Pay Cash' : paymentMethodGroup1 === 'visa' ? 'Visa' : 'QR-Code'}`;

        const remainingAmount = latestBooking.PriceValue - latestBooking.AmountPaid;

        const paymentData = preparePaymentData(
          referenceCode,
          latestBooking.BookingID,
          latestBooking.CustomerID,
          latestBooking.EmployeeID,
          latestBooking.PriceValue,
          remainingAmount,
          notes
        );
        console.log('Prepared Payment Data from latest booking:', paymentData);

        // Send payment data to the payment API
        await sendPaymentData(paymentData);
        // Send email notification
        await sendEmailNotification(latestBooking.BookingID, latestBooking.CustomerEmail);

        // Send SMS notification
        await sendSmsNotification(latestBooking.BookingID, latestBooking.CustomerTelephone);
      }

    } catch (error) {
      console.error('Error fetching latest booking data:', error);
    }
  };

  const sendEmailNotification = async (bookingID, customerEmail) => {
    try {
      const emailData = {
        order_id: bookingID,
        email: customerEmail
      };

      console.log('Sending Email Notification:', emailData); // Log email data for verification

      const response = await axios.post(API_URLS.SEND_EMAIL, emailData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      console.log('Email Notification API response:', response.data);
    } catch (error) {
      console.error('Error sending email notification:', error);
    }
  };

  const sendSmsNotification = async (bookingID, customerPhone) => {
    try {

      // ลบช่องว่างทั้งหมด
      customerPhone = customerPhone.replace(/\s+/g, '');
      // Ensure the phone number starts with a '+'
      if (!customerPhone.startsWith('+')) {
        customerPhone = `+66${customerPhone}`;
      }

      const smsData = {
        sms_recipients: [customerPhone],
        sms_text: `Thank you for your payment! OrderID ${bookingID} isaanspa`,
        sms_type: "SMS_WORLD",
        sms_sender: "PURPLE LION PACIFIC"
      };

      console.log('Sending SMS Notification:', smsData); // Log SMS data for verification

      const response = await axios.post(API_URLS.SEND_SMS, smsData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      console.log('SMS Notification API response:', response.data);
    } catch (error) {
      console.error('Error sending SMS notification:', error);
    }
  };


  const handleSubmitClick = async () => {
    setLoading(true);
    await handleSubmit(paymentAmount);
    setLoading(false);
    onHide();
    hideServiceTypeSidebar();
    setTopSidebarVisible(false);
    setShouldFetch(true);
    if (typeof onHideDialog === 'function') onHideDialog();
    if (typeof refreshParentData === 'function') refreshParentData();

    // Dispatch the action
    dispatch({ type: 'SET_HANDLE_SUBMIT_CLICK_STATUS', payload: 1 });

    // Fetch and process the latest booking details
    await fetchLatestBookingData();

  };



  const sendPaymentData = async (paymentData) => {
    try {
      console.log('Sending Payment Data:', paymentData); // Print the payment data for verification
      const response = await axios.post(API_URLS.CREATE_PAYMENT, paymentData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
      console.log('Payment API response:', response.data);
    } catch (error) {
      console.error('Error sending payment data:', error);
    }
  };

  // Function to scroll sidebar content to the bottom
  const scrollToBottom = () => {
    if (sidebarContentRef.current) sidebarContentRef.current.scrollTop = sidebarContentRef.current.scrollHeight;
  };


  useEffect(() => {
    console.log("topSidebarVisible changed:", topSidebarVisible);
    
    if (topSidebarVisible) {
      console.log("Sidebar is open");
      // Perform any additional logic required when sidebar opens
    } else {
      console.log("Sidebar is closed");
      // Perform any additional logic required when sidebar closes
    }
  }, [topSidebarVisible]);  // This will run every time topSidebarVisible changes
  



  return (
    <>


      <Sidebar
        visible={isSidebarVisible}
        position="bottom"
        onHide={() => {
          console.log("Closing sidebar...");
          toggleTopSidebarVisible();   // Reset the visibility state when sidebar is closed
        }}
        className="custom-bottom-sidebar"
      >
        <div className="sidebar-content" ref={sidebarContentRef}>
          <Row>
            <Col xs={4} className="cumulative-total-container text-center">
              <Row>
                <Col xs={3} className="cumulative-total-container text-center">
                  <PaymentLabels />
                </Col>
                <Col xs={4} className="payment-method-container payment-InputSwitch">
                  <InputSwitchWrapper paymentMethodGroup1={paymentMethodGroup1} handlePaymentMethodChangeGroup1={handlePaymentMethodChangeGroup1} />
                </Col>
                <Col xs={4} className="credit-card-input-container">
                  <PaymentDetails paymentMethodGroup1={paymentMethodGroup1} paymentDetails={paymentDetails} />
                </Col>
              </Row>

            </Col>

            <Col xs={4} className="payment-method-container">
              <PaymentMethodContainer
                paymentMethodGroup2={paymentMethodGroup2}
                handlePaymentMethodChangeGroup2={handlePaymentMethodChangeGroup2}
                paymentAmount={paymentAmount}
                handlePaymentAmountChange={handlePaymentAmountChange}
                handleInputFocus={handleInputFocus}
                handleInputBlur={handleInputBlur}
                paymentPercentage={paymentPercentage}
                handlePaymentPercentageChange={handlePaymentPercentageChange}
              />
            </Col>

            <Col xs={4} className="d-flex align-items-center justify-content-end">
              {loading ? (
                <ProgressSpinner />
              ) : (
                <Button onClick={handleSubmitClick} className="full-submit-button">
                  <div className="confirm-button-content">
                    <div className="confirm-text">CONFIRM</div>
                    <div className="cumulative-total-text">CUMULATIVE TOTAL: ${cumulativePrice}</div>
                  </div>
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Sidebar>
    </>
  );
};

export default EventDialogServiceTimeSidebar;

