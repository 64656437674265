///var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/pages/Dashboard.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../components/Header';
import CalendarView from '../components/CalendarView';
import DataQueues from '../components/DataQueues';
import Member from './Member';
import EventDialogServiceTimeSidebar from '../components/DataQueues/EventDialogServiceTimeSidebar';
import '../css/Dashboard.css';

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [refreshFlag, setRefreshFlag] = useState(false);

  const handleSubmitClickStatus = useSelector(state => state.handleSubmitClickStatus);
  const dispatch = useDispatch();

  const refreshData = () => {
    setRefreshFlag(prevFlag => !prevFlag);
  };

  const onDateChange = (newDate) => {
    setSelectedDate(newDate.split('T')[0]);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    if (handleSubmitClickStatus === 1) {
      refreshData();
      dispatch({ type: 'SET_HANDLE_SUBMIT_CLICK_STATUS', payload: 0 });
    }
  }, [handleSubmitClickStatus, dispatch]);

  const renderContent = () => {
    switch (location.pathname) {
      case '/Member':
        return <Member />;
      default:
        return (
          <>
            <div className="main-content panels">
              <CalendarView onDateClick={setSelectedDate} />
            </div>

            <div className="main-and-calendar">
              <DataQueues
                refreshFlag={refreshFlag}
                selectedDate={selectedDate}
                onDateChange={onDateChange}
                setRefreshFlag={setRefreshFlag}
                refreshData={refreshData}
                EventDialogServiceTimeSidebar={EventDialogServiceTimeSidebar}
              />
            </div>
          </>
        );
    }
  };

  return (
    <div className="dashboard">
      <div className="dashboard-main">
        <Header refreshData={refreshData} />
        <div className="content-area">
          {location.pathname === '/Member' && <Member />}
          {location.pathname !== '/Member' && renderContent()}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
