///var/www/website2024/admin.siamweeds.com/myapp/src/pages/Services.js

import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import Header from '../components/Header';
import ServicesTable from '../components/ServicesTable';
import ServicesForm from '../components/ServicesForm';

import '../css/Member.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowsRotate } from '@fortawesome/free-solid-svg-icons';

// Import the API endpoints
import { API_ENDPOINTS } from '../api/api';


const Services = () => {
  const [servicesData, setServicesData] = useState([]);
  const [currentService, setCurrentService] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [formMode, setFormMode] = useState('add');
  const [searchQuery, setSearchQuery] = useState('');
  const [pageLength, setPageLength] = useState(10);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const commonHeaders = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
  };

  const handleCancelEdit = () => {
    setIsFormVisible(false);
  };

  const handleRefresh = () => {
    fetchServicesData();
  };

  const handlePageLengthChange = (e) => {
    setPageLength(parseInt(e.target.value, 10));
  };

  useEffect(() => {
    fetchServicesData();
  }, []);

  const fetchServicesData = async () => {
    try {

      const response = await fetch(API_ENDPOINTS.SERVICES_VIEW, {
        method: 'GET',
        headers: commonHeaders,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const dataWithKey = data.map(item => ({ ...item, key: item.ServiceID }));
      setServicesData(dataWithKey);
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const handleEditService = (serviceId) => {
    const serviceToEdit = servicesData.find((service) => service.ServiceID === serviceId);
    setCurrentService(serviceToEdit);
    setFormMode('edit');
    setIsFormVisible(true);
  };

  const handleAddService = () => {
    setCurrentService(null);
    setFormMode('add');
    setIsFormVisible(true);
  };

  const handleSaveService = async (data) => {
    setIsSaving(true);
    // หากมีการเก็บ userID ใน localStorage หรือที่อื่น สามารถรับค่ามาได้จากที่นี่
    const userID = localStorage.getItem('user_ID');
  
    // จัดการเพิ่ม user_ID ให้กับข้อมูลก่อนส่ง
    let dataToSend = { ...data, user_ID: userID }; // เพิ่ม user_ID ในทั้งกรณี 'add' และ 'edit    '
  
    const url = formMode === 'edit'
    ? API_ENDPOINTS.SERVICES_EDIT
    : API_ENDPOINTS.SERVICES_CREATE;

    try {
      const response = await fetch(url, {
        method: formMode === 'edit' ? 'PUT' : 'POST',
        headers: commonHeaders,
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        await fetchServicesData();
        setIsFormVisible(false);
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async (selectedServiceIds) => {
    setIsDeleting(true);
    try {
      const response = await fetch(API_ENDPOINTS.SERVICES_DELETE, {
        method: 'DELETE',
        headers: commonHeaders,
        body: JSON.stringify(selectedServiceIds), // Send the array of IDs directly
      });

      if (!response.ok) {
        throw new Error('Failed to delete services');
      }

      fetchServicesData();
    } catch (error) {
      console.error('Error deleting services:', error);
    } finally {
      setIsDeleting(false);
    }
  };
  return (
    <div className="dashboard">
      <Sidebar />
      <div className="dashboard-main">
        <Header
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          placeholderText="Services in to search..."
          onRefresh={handleRefresh}
          onBack={isFormVisible ? handleCancelEdit : undefined}
        />

        <div className="member-table-container">
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <div className="select-container me-md-2">
              <select
                id="pageLengthSelect"
                className="form-select"
                value={pageLength}
                onChange={handlePageLengthChange}
              >
                <option value="10">10 Rows</option>
                <option value="20">20 Rows</option>
                <option value="50">50 Rows</option>
              </select>
            </div>
            <button onClick={handleAddService} className="btn btn-dark btn-sm">
              <FontAwesomeIcon icon={faPlus} /> Create New Service
            </button>
          </div>

          {isFormVisible ? (
            <ServicesForm
              serviceData={currentService}
              mode={formMode}
              onCancel={handleCancelEdit}
              onSave={handleSaveService}
            />
          ) : (
            <ServicesTable
              servicesData={servicesData}
              onEditService={handleEditService}
              onDelete={handleDelete}
              searchQuery={searchQuery}
              pageLength={pageLength}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Services;
