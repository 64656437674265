// /var/www/website2024/admin.siamweeds.com/myapp/src/components/DataQueues/EventContent.js
import React from 'react';

const EventContent = ({ eventInfo }) => {
  if (!eventInfo || !eventInfo.event || !eventInfo.event.extendedProps) return null;

  const { extendedProps } = eventInfo.event;
  const priceStyle = extendedProps.serviceStatus === '1' ? 'color:green;' : 'color:red;';

  const htmlContent = `
    <div style="display:flex; flex-direction:column; align-items:start; font-size: small;">
      <div><i class="pi pi-clock" style="margin-right:4px;"></i>${extendedProps.time} - ${extendedProps.endtime}</div>
      <div style="${priceStyle}"><i class="pi pi-money-bill" style="margin-right:4px;"></i>${extendedProps.price}</div>
    </div>
  `;

  return (
    <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
  );
};

export default EventContent;
