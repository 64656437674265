// /var/www/website2024/admin.siamweeds.com/myapp/src/pages/Booking.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Sidebar from './Sidebar';
import Header from '../components/Header';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import '../css/Booking.scss';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

import Spinner from 'react-bootstrap/Spinner';
import { Badge } from 'primereact/badge';
import { API_URLS } from '../api/api';

// Declare the userId as a global variable
const userId = localStorage.getItem('user_ID');
const token = localStorage.getItem('token');

const Booking = () => {
  const [bookings, setBookings] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]); // Unified state for status options
  const [refreshData, setRefreshData] = useState(false);
  const toast = useRef(null);
  const userId = localStorage.getItem('user_ID');
  const token = localStorage.getItem('token');
  const [expandedRows, setExpandedRows] = useState(null);
  const [bookingDetails, setBookingDetails] = useState({});
  const [userOptions, setUserOptions] = useState([]);
  const [updatedEmployees, setUpdatedEmployees] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [detailsCountByBooking, setDetailsCountByBooking] = useState({});



  // Adjusted function to fetch status options
  const fetchStatusOptions = async () => {
    try {
      const response = await axios.get(API_URLS.STATUS_LIST);
      setStatusOptions(response.data.map(status => ({ label: status.name, value: status.id })));
    } catch (error) {
      console.error('Error fetching status options:', error);
    }
  };

  // Function that fetches booking details. It updates both booking details and their counts.
  const fetchBookingDetailsAndUpdateCount = async (bookingIDs) => {
    // Handle fetching for multiple booking IDs or a single ID
    const idsToFetch = Array.isArray(bookingIDs) ? bookingIDs : [bookingIDs];

    idsToFetch.forEach(async (bookingID) => {
      try {
        const response = await axios.get(API_URLS.BOOKING_DETAILS_EXTENDED(bookingID), {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Update booking details and count for each fetched booking
        setBookingDetails(prev => ({ ...prev, [bookingID]: response.data }));
        const count = response.data.filter(detail => !detail.EmployeeID || detail.EmployeeID === 0).length;
        setDetailsCountByBooking(prev => ({ ...prev, [bookingID]: count }));
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Handle missing details gracefully
          console.log(`No details found for booking ID: ${bookingID}`);
          setDetailsCountByBooking(prev => ({ ...prev, [bookingID]: 0 }));
        } else {
          console.error(`Error fetching details for booking ${bookingID}:`, error);
        }
      }
    });
  };


  // Adjust fetchBookings to call fetchAllBookingDetails after bookings are fetched
  const fetchBookings = async () => {
    setIsLoading(true);
    try {
      const bookingResponse = await axios.get(API_URLS.BOOKING_DETAILS(), {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      if (bookingResponse.status === 200 && bookingResponse.data) {
        const enrichedBookings = await Promise.all(bookingResponse.data.map(async (booking) => {
          try {
            // Fetch customer details for each booking
            const customerResponse = await axios.get(
              API_URLS.CUSTOMER_DETAILS(booking.CustomerID),
              { headers: { Authorization: `Bearer ${token}` } }
            );
            // Combine booking with its customer details
            return { ...booking, customerDetails: customerResponse.data };
          } catch (error) {
            console.error('Error fetching customer details:', error);
            return booking; // Return booking without customer details in case of an error
          }
        }));

        setBookings(enrichedBookings);
        const bookingIDs = enrichedBookings.map(booking => booking.BookingID);
        fetchBookingDetailsAndUpdateCount(bookingIDs);
      }
    } catch (error) {
      console.error('Error fetching bookings:', error);
    } finally {
      setIsLoading(false);
    }
  };




  // Function to fetch users for the dropdown
  const fetchUsers = async () => {
    try {
      const response = await axios.get(API_URLS.ALL_USERS(userId, '', 2), {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setUserOptions(response.data.map(user => ({
        label: `${user.first_name} ${user.sur_name}`, // Concatenating first and last names
        value: user.user_ID
      })));
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchStatusOptions();
    fetchBookings();
  }, [refreshData]);


  // Handle employee change and refresh count immediately
  const handleEmployeeChange = async (e, rowData) => {
    const newEmployeeId = e.value;
    const BookingDetailID = rowData.BookingDetailID;
    const CustomerID = rowData.CustomerID;

    const ServiceDate = new Date(rowData.ServiceDate).toLocaleDateString('en-CA');
    const ServiceTime = rowData.ServiceTime;
    const PriceType = rowData.PriceType;


    try {
      const response = await axios.put(API_URLS.UPDATE_EMPLOYEE(BookingDetailID),
        { status_ID: newEmployeeId },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.current.show({ severity: 'success', summary: 'Employee Updated', detail: 'The employee has been successfully updated.' });
        // Refresh booking details and count for the updated booking
        fetchBookingDetailsAndUpdateCount(rowData.BookingID);

        // console.log('rowData:', rowData);
        // console.log('New Employee ID:', newEmployeeId);
        // console.log('Booking Detail ID:', BookingDetailID);
        // console.log('CustomerID:', CustomerID);
        // console.log('ServiceDate:', ServiceDate);
        // console.log('ServiceTime:', ServiceTime);
        // console.log('PriceType:', PriceType);

        const ExpectedStartTime = `${ServiceDate}T${ServiceTime}`;
        //console.log('ExpectedStartTime:', ExpectedStartTime);

        const TimestampStartTime = new Date(ExpectedStartTime).getTime();

        // Improved regex to capture fractional hours as well
        let [_, durationHours, fractionalHours] = PriceType.match(/(\d+)(\.\d+)?\s*hr/) || [0, 0, 0];
        let durationMinutes = PriceType.match(/\b(\d+)\s*min/)?.[1] || 0;

        // Convert fractional hours to minutes and add to durationMinutes
        if (fractionalHours) {
          durationMinutes = parseFloat(durationMinutes) + parseFloat(fractionalHours) * 60;
        }
        const totalDurationMs = (parseInt(durationHours) * 3600000) + (parseInt(durationMinutes) * 60000);

        const ExpectedEndTime = new Date(TimestampStartTime + totalDurationMs);

        const formattedExpectedServiceEndTime = `${ExpectedEndTime.getFullYear()}-${(ExpectedEndTime.getMonth() + 1).toString().padStart(2, '0')}-${ExpectedEndTime.getDate().toString().padStart(2, '0')}T${ExpectedEndTime.getHours().toString().padStart(2, '0')}:${ExpectedEndTime.getMinutes().toString().padStart(2, '0')}:${ExpectedEndTime.getSeconds().toString().padStart(2, '0')}`;

        //console.log('Formatted ExpectedService_EndTime:', formattedExpectedServiceEndTime);

        const createdAt = new Date().toISOString().slice(0, 19); // Format createdAt as 'YYYY-MM-DDTHH:MM:SS'

        // Formatting payload
        const queueAssignmentPayload = [{
          "EmployeeID": newEmployeeId.toString(),
          "BookingID": rowData.BookingID.toString(),
          "BookingDetailID": rowData.BookingDetailID.toString(),
          "CustomerID": CustomerID.toString(),
          "Priority": 1, // Assuming priority is 1 for this example
          "Status": "1", // Assuming status is '1' for active/queued
          "Type": "1", // 1=services | 2 = product
          "Description": "Initial queue assignment",
          "CreatedAt": createdAt, // Current timestamp
          "ExpectedStartTime": ExpectedStartTime.toString(), // Example start time, adjust as necessary
          "ExpectedEndTime": formattedExpectedServiceEndTime.toString(), // Example end time, adjust as necessary 
        }];

        console.log('queueAssignmentPayload:', queueAssignmentPayload);

        // Sending the payload to the API
        // Use the Fetch API to send the request
        fetch(API_URLS.ASSIGN_QUEUE(), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Make sure the token is correctly included
          },
          body: JSON.stringify(queueAssignmentPayload),
        })
          .then(response => response.json()) // Convert response to JSON
          .then(data => {
            console.log('API Response:', data);
            toast.current.show({
              severity: 'success',
              summary: 'Queue Assignment',
              detail: 'Queue assignment successfully sent.'
            });
          })
          .catch(error => {
            console.error('API Error:', error);
            toast.current.show({
              severity: 'error',
              summary: 'Queue Assignment Failed',
              detail: 'Failed to send queue assignment.'
            });
          });
      } else {
        throw new Error('Failed to update employee for the booking detail.');
      }
    } catch (error) {
      console.error('Error updating employee:', error);
      toast.current.show({ severity: 'error', summary: 'Update Failed', detail: 'Failed to update the employee.' });
    }
  };



  const employeeDropdownTemplate = (rowData) => {
    const currentEmployeeId = updatedEmployees[rowData.BookingDetailID] || rowData.EmployeeID;
    const optionsWithZeroValue = [
      { label: 'Be Free', value: 0 }, // Option for when the value is 0
      ...userOptions // Add existing user options
    ];

    return (
      <Dropdown
        value={currentEmployeeId}
        options={optionsWithZeroValue}
        onChange={(e) => handleEmployeeChange(e, rowData)} // Implement this function based on your requirements
        placeholder="Select an Employee"
        optionLabel="label"
      />
    );
  };

  const fetchBookingDetails = async (bookingID, preCalculate = false) => {
    if (!bookingID) {
      console.error('Invalid booking ID');
      return;
    }

    try {
      const response = await axios.get(API_URLS.BOOKING_DETAIL_PRICE(bookingID), {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Update state with fetched details
      setBookingDetails(prev => ({ ...prev, [bookingID]: response.data }));

      // Calculate the count for this booking's details and update state if preCalculate is true
      if (preCalculate) {
        const count = response.data.filter(detail => !detail.EmployeeID || detail.EmployeeID === 0).length;
        setDetailsCountByBooking(prev => ({ ...prev, [bookingID]: count }));
      }
    } catch (error) {
      console.error('Error fetching booking details:', error);
      // Show appropriate error messages based on the error
      if (error.response && error.response.status === 404) {
        toast.current.show({ severity: 'error', summary: 'Details Not Found', detail: 'No details available for this booking.' });
      } else {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch booking details.' });
      }
    }
  };


  // Function to handle row toggle
  const onRowToggle = (e) => {
    setExpandedRows(e.data);
    Object.keys(e.data).forEach(async (bookingID) => {
      if (!bookingDetails[bookingID]) {
        await fetchBookingDetails(bookingID);
      }
    });
  };

  const priceTemplate = (rowData) => {
    return `${rowData.BookingDetailPrice}`;
  };

  const serviceAndTypeTemplate = (rowData) => {
    return (
      <div>
        <div><b>Package:</b> {rowData.ServiceName}</div>
        <div><b>Type:</b> {rowData.PriceType}</div>
      </div>
    );
  };


  // Row expansion template
  const rowExpansionTemplate = (data) => {
    const bookingDetailsData = bookingDetails[data.BookingID];

    const rowClassName = (data) => {
      return (!data.EmployeeID || data.EmployeeID === 0) ? 'employee-cell-red' : 'employee-row-green';
    };

    return (
      <div>
        <DataTable value={bookingDetailsData} className="p-datatable-details" paginator rows={5}
          rowClassName={rowClassName} // Apply the row class name function here          
        >
          <Column field="BookingDetailID" header="Booking Detail ID"></Column>
          <Column field="ServiceDate" header="Service Date" body={serviceDateTemplate}></Column>
          <Column field="ServiceTime" header="Service Time"></Column>
          <Column body={serviceAndTypeTemplate} header="Package & Type"></Column>
          <Column field="StatusName" header="Status"></Column>
          <Column field="EmployeeID" header="Employee" body={employeeDropdownTemplate} sortable filter></Column>
          <Column body={priceTemplate} header="Price"></Column>
          {/* Add more columns for booking details as needed */}
        </DataTable>
      </div>
    );
  };

  // Template to display customer details in the DataTable
  const customerDetailsTemplate = (rowData) => {
    //console.log("rowData:", rowData); // Print rowData ที่ได้รับมาใน console
    if (rowData.customerDetails) {
      return (
        <div>
          <p>{rowData.customerDetails.first_name} {rowData.customerDetails.sur_name}</p>
          <p>{rowData.customerDetails.email}</p>
          <p>{rowData.customerDetails.telephone}</p>
        </div>
      );
    } else {
      // You might want to return a different placeholder or spinner here if data is still expected
      return <p>Loading...</p>;
    }
  };

  // Handles booking status update
  const handleBookingStatusChange = async (e, rowData) => {
    const newStatusId = e.value;
    try {
      const response = await axios.put(
        API_URLS.UPDATE_BOOKING_STATUS(rowData.BookingID),
        { status_ID: newStatusId },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Update local state to reflect the status change without reloading all bookings
        const updatedBookings = bookings.map(booking =>
          booking.BookingID === rowData.BookingID ?
            { ...booking, BookingStatus: newStatusId, BookingStatusName: statusOptions.find(option => option.value === newStatusId)?.label } :
            booking
        );
        setBookings(updatedBookings);

        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Booking status updated successfully.' });
      } else {
        throw new Error('Failed to update booking status.');
      }
    } catch (error) {
      console.error('Error updating booking status:', error);
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error updating booking status. Please try again.' });
    }
  };


  const bookingStatusDropdownTemplate = (rowData) => {
    // Find the current booking status from the unified status options
    const currentStatus = statusOptions.find(option => option.value === rowData.BookingStatus);
    return (
      <Dropdown
        value={currentStatus ? currentStatus.value : null}
        options={statusOptions}
        onChange={(e) => handleBookingStatusChange(e, rowData)}
        placeholder="Select a Status"
        optionLabel="label"
      />
    );
  };



  const formatDate = (value) => {
    if (value) {
      const date = new Date(value);
      return date.toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      });
    }
    return "";
  };

  const serviceDateTemplate = (rowData) => {
    return <span>{formatDate(rowData.ServiceDate)}</span>;
  };


  const paymentStatusDropdownTemplate = (rowData) => {
    const currentStatus = statusOptions.find(option => option.value === rowData.PaymentStatus);
    return (
      <Dropdown
        value={currentStatus ? currentStatus.value : null}
        options={statusOptions}
        onChange={(e) => handleStatusChange(e, rowData)}
        placeholder="Select a Status"
        optionLabel="label"
      />
    );
  };


  const handleStatusChange = async (e, rowData) => {
    const newStatusId = e.value;
    try {
      const response = await axios.put(
        API_URLS.UPDATE_PAYMENT_STATUS(rowData.BookingID),
        { status_ID: newStatusId },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Locally update the payment status for the specific booking
        const updatedBookings = bookings.map(booking => {
          if (booking.BookingID === rowData.BookingID) {
            return {
              ...booking,
              PaymentStatus: newStatusId,
              PaymentStatusName: statusOptions.find(option => option.value === newStatusId)?.label
            };
          }
          return booking;
        });

        setBookings(updatedBookings); // Update the state with the modified bookings array

        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Payment status updated successfully' });
      } else {
        throw new Error('Failed to update payment status');
      }
    } catch (error) {
      console.error('Error updating payment status:', error);
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error updating payment status. Please try again.' });
    }
  };


  const bookingIDTemplate = (rowData) => {
    const handleInvoiceNavigation = () => {
      localStorage.setItem('localBookingReduxID', rowData.BookingID.toString());
      window.open(`/Invoice?BookingID=${rowData.BookingID}`, '_blank');
    };

    return (
      <Button
        label={rowData.BookingID.toString()}
        icon="pi pi-search"
        className="p-button-raised p-button-rounded p-button-success"
        onClick={handleInvoiceNavigation}
      />
    );
  };

  const expanderBodyTemplate = (rowData) => {
    const toggleExpandedRow = () => {
      setExpandedRows(prevExpandedRows => {
        const newExpandedRows = { ...prevExpandedRows };
        if (newExpandedRows[rowData.BookingID]) {
          delete newExpandedRows[rowData.BookingID];
        } else {
          if (!bookingDetails[rowData.BookingID]) {
            fetchBookingDetails(rowData.BookingID);
          }
          newExpandedRows[rowData.BookingID] = true;
        }
        return newExpandedRows;
      });
    };

    const isExpanded = expandedRows && expandedRows.hasOwnProperty(rowData.BookingID);
    // Use the pre-calculated count from state
    const detailsCount = detailsCountByBooking[rowData.BookingID] || 0;

    return (
      <>
        <Button
          icon={isExpanded ? "pi pi-chevron-down" : "pi pi-chevron-right"}
          onClick={toggleExpandedRow}
          className="p-button-rounded p-button-text p-button-plain"
          aria-controls="rowToggle"
        />
        <Badge value={detailsCount} severity={detailsCount > 0 ? "danger" : "success"} style={{ marginLeft: '0.5em' }} />
      </>
    );
  };




  return (
    <>
      <Toast ref={toast} />
      <div className="dashboard">
        <Sidebar />
        <div className="dashboard-main">
          <Header searchQuery={globalFilter} setSearchQuery={setGlobalFilter} />

          {isLoading ? (
            <div className="spinner-overlay">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (

            <DataTable
              value={bookings}
              expandedRows={expandedRows}
              onRowToggle={onRowToggle}
              rowExpansionTemplate={rowExpansionTemplate}
              dataKey="BookingID"
              paginator
              className="p-datatable-customers"
              rows={10}
              globalFilter={globalFilter}
              header="Manage Booking Data"
            >
              <Column expander style={{ width: '3em' }} body={expanderBodyTemplate} />

              {/* <Column header="Queues" expander style={{ width: '3em' }} /> */}
              <Column field="BookingID" header="Booking ID" body={bookingIDTemplate} sortable filter filterPlaceholder="Search by ID"></Column>

              <Column field="CustomerID" header="Customer Info" body={customerDetailsTemplate} sortable filter></Column>
              <Column field="TotalAmount" header="Total Amount" sortable filter></Column>
              <Column field="AmountPaid" header="Amount Paid" sortable filter></Column>
              <Column field="BookingStatusName" header="Booking Status" body={bookingStatusDropdownTemplate} sortable filter></Column>
              <Column field="PaymentStatusName" header="Payment Status" body={paymentStatusDropdownTemplate} sortable filter></Column>

              <Column field="date" header="Date" body={(rowData) => formatDate(rowData.date)} sortable filter></Column>

              <Column field="time" header="Time" sortable filter></Column>
              {/* Add more columns as needed based on your JSON data structure */}
            </DataTable>
          )}
        </div>
      </div>
    </>
  );
}

export default Booking;
