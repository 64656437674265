///var/www/website2024/admin.siamweeds.com/myapp/src/components/DataQueues/fetchEvents.js

import axios from 'axios';
import createEventObject from './createEventObject';
import { API_URLS } from '../../api/api';

const fetchEvents = async (selectedDate, setEventDetails, showToast, bookingStatus = '!=6', limit = 100) => {
  try {
    const params = {
      date_from: selectedDate,
      date_to: selectedDate,
      limit: limit
    };

    // Check and format the BookingStatus if necessary
    if (bookingStatus) {
      params.BookingStatus = bookingStatus;
    }
  

    const fullUrl = `${API_URLS.EVENTS}?${new URLSearchParams(params).toString()}`;
    console.log('Requesting URL:', fullUrl);

    const response = await axios.get(API_URLS.EVENTS, {
      params: params,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });

    // Log the response data to see its structure
    console.log('API fetchEvents Response Data:', response.data);

    if (Array.isArray(response.data)) {
      const apiEventDetails = response.data.reduce((acc, event) => {
        const newEvent = createEventObject(event);
        const dateKey = new Date(event.ServiceDate).toISOString().split('T')[0];

        acc[dateKey] = acc[dateKey] || [];
        acc[dateKey].push(newEvent);

        return acc;
      }, {});

      setEventDetails(apiEventDetails);
      console.log('Transformed event details:', apiEventDetails);
    } else {
      // Handle the case where response data is not an array
      console.warn('No booking details found:', response.data.message);
      setEventDetails({});
      if (showToast) {
        showToast('warning', 'No booking details found');
      }
    }
  } catch (error) {
    // Log the entire error object
    console.error('Error fetching events:', error);

    if (showToast) {
      showToast('danger', 'Error fetching events');
    }
  }
};

export default fetchEvents;
